<template>
  <div class="yimiao">
    <div>
      <div>
        <van-cell-group>
          <van-field
            v-model="datafrom.Title"
            required
            label="标 题"
            placeholder="请输入促销活动标题"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.StartDate"
            label="开始日期"
            @click="datafromStartDate = true"
            readonly="readonly"
            required
            placeholder="请选择开始日期"
          />
          <van-popup
            v-model="datafromStartDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="请选择开始日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="datafromStartDate = false"
              @confirm="datafromStartDateClick"
            >
            </van-datetime-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.EndDate"
            label="结束日期"
            @click="datafromEndDate = true"
            readonly="readonly"
            required
            placeholder="请选择结束日期"
          />
          <van-popup
            v-model="datafromEndDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="请选择结束日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="datafromEndDate = false"
              @confirm="ondatafromEndDate"
            >
            </van-datetime-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <div class="submit" style="padding: 10px 7px">
            <span
              style="color: red; font-size: 14px; letter-spacing: 8px"
            ></span>
            <span style="color: #646566; font-size: 14px">内容</span>
          </div>
          <div style="width: 90%; margin: auto">
            <Editor :value.sync="datafrom.Content" />
          </div>
        </van-cell-group>
        <div class="submit" style="padding: 10px 7px">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566; font-size: 14px">促销海报</span>
        </div>
        <van-uploader
          v-model="jkImageList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :before-delete="jkDelete"
          :deletable="true"
        />
        <van-cell-group>
          <van-field
            v-model="datafrom.Quota"
            label="限 额"
            placeholder="请输入限额（名）"
          />
        </van-cell-group>
        <div class="submit" style="padding: 10px 7px">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"
            >*</span
          >
          <span style="color: #646566; font-size: 14px">状 态</span>
          <div style="margin-left: 17%; display: inline-block">
            <van-radio-group
              v-model="datafrom.Status"
              style="line-height: 20px"
              direction="horizontal"
            >
              <van-radio name="1">筹备中</van-radio>
              <van-radio name="2">进行中</van-radio>
              <van-radio name="3">已结束</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div style="margin: 10px">
          <van-uploader
            max-count="1"
            accept="video/*"
            :after-read="handleAvatarSucces"
          >
            <van-button icon="video-o" plain type="info">上传视频</van-button>
          </van-uploader>
          <div
            v-if="datafrom.Video"
            style="text-align: center; margin-top: 10px"
          >
            <video style="width: 90%" :src="datafrom.Video" controls="controls">
              您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>

        <van-cell-group>
          <van-field
            v-model="datafrom.Remark"
            label="备 注"
            type="textarea"
            placeholder="请输入备注"
          />
        </van-cell-group>
      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button type="info" style="margin: 10px 0" @click="postfrom()"
          >保存</van-button
        >
        <!-- <van-button style="border: 1px solid #e6e6e6;margin:10px 0;"
          type="
          default"
          @click="fanhui()">返回</van-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import {
  WxGetMyGoodsSortList,
  WxSaveMyPromotion,
  WxGetMyPromotion,
  UploadQiniuUpToken,
  UploadQiniuVideo,
} from "@/api/RealInfo";
import axios from "axios";
import Editor from "./Editor/index"; // 富文本编辑器
Vue.use(Toast);
import { GetStaff, WxSaveStaff } from "@/api/RealInfo";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Editor,
  },
  data() {
    return {
      // 商品图片
      jkImageList: [],
      NativeCantonCodedata: [],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        PromotionId: 0, //id、
        Title: "",
        Poster: "",
        StartDate: "",
        EndDate: "",
        Quota: "",
        Content: "",
        Video: "",
        Status: "1",
        Remark: "",
      },
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromStartDate: false,
      datafromEndDate: false,
      recumenFend: false, //出生日期
      sortIdlist: [],
      cateIdlist: [],
      cateIdShow: false,
      sortIdShow: false,
      // 获取七牛云token
      qiniutokent: {}, //上传视频传参
    };
  },
  created() {
    this.qiniutoken();
    this.shopXq();
  },
  methods: {
    // 获取七牛云token
    qiniutoken() {
      UploadQiniuUpToken().then((res) => {
        this.qiniutokent.token = res.data.token;
      });
    },
    // 上传 相关
    handleAvatarSucces(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let url = "http://upload-z2.qiniup.com";
      let formData = new FormData();
      formData.append("token", this.qiniutokent.token);
      formData.append("file", file.file); //必须是file.file，不然会报错
      //添加请求头
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios.post(url, formData, config).then((res) => {
        this.datafrom.Video = "http://videolib.dexian.ren/" + res.data.key;
      });
    },

    // 商品类别选择器
    onsortId(val) {
      this.datafrom.SortId = val.SortId;
      this.datafrom.SoName = val.Name;
      this.sortIdShow = false;
    },
    // 获取详情
    shopXq: function () {
      console.log(this.$route);
      if (this.$route.params.id == "1") {
        this.datafrom = {
          PromotionId: 0, //id、
          Title: "",
          Poster: "",
          StartDate: "",
          EndDate: "",
          Quota: "",
          Content: "",
          Video: "",
          Status: "1",
          Remark: "",
        };
      } else {
        console.log(this.$route.params);
        WxGetMyPromotion({
          openID: getOpenId(),
          promotionId: this.$route.params.PromotionId,
        }).then((res) => {
          this.bangdingShow = true;
          if (res.data.code == 0) {
            console.log(res.data.data);
            this.datafrom = res.data.data;
            this.datafrom.Status = res.data.data.Status + "";
            if (res.data.data.Poster != "") {
              var jkImageLists = [res.data.data.PosterUrl];
              var arr = [];
              jkImageLists.forEach(function (imgUrl) {
                arr.push({ url: imgUrl });
              });
              this.jkImageList = arr;
            }

            // this.datafrom.OrgCode = res.data.data.OrgCode;
            // this.datafrom.OrgName = res.data.data.OrgName;

            // this.datafrom.EType = res.data.data.EType;
            // this.datafrom.NationalityName = res.data.data.ShopTypeDesc;
          }
        });
      }
    },

    //上传商品图片
    afterRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.data);
              that.datafrom.Poster = res.data.data;
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商品图片
    jkDelete(file) {
      this.jkImageList = [];
      this.datafrom.Poster = "";
    },

    // 提交
    postfrom: function () {
      console.log(this.$route.params);
      console.log(this.datafrom);
      if (this.datafrom.Title == "") {
        Toast.fail("请输入标题");
        return false;
      }
      if (this.datafrom.StartDate == "") {
        Toast.fail("请选择开始日期");
        return false;
      }
      if (this.datafrom.EndDate == "") {
        Toast.fail("请选择结束日期");
        return false;
      }
      // this.datafrom.shopId
      this.datafrom.OpenID = getOpenId();
      this.datafrom.ShopId = this.$route.params.shopId;
      // this.datafrom.OpenID = 'oh5yu5MZRYsqSdFQIXzJ4mnwRAPM';
      WxSaveMyPromotion(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "保存成功!",
          }).then(() => {
            // this.$router.push({
            //   name: "register",
            // });
            this.$router.go(-1);
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "保存失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    gitocodelist() {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.push({
        name: "ygList",
        query: { seId: this.$route.query.seId, ekind: this.$route.query.ekind },
      });
    },
    // 获取身份证
    IdNum(value) {
      console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    go(val) {
      var iden =
        this.datafrom.IdCard == ""
          ? this.datafrom.IdCard
          : this.datafrom.IdCard;
      // var iden = this.dataForms.IdNumber;
      console.log(iden);
      var id = iden.substring(0, 6);
      // this.datafrom.NativeCantonCode = id;
      // console.log(this.datafrom.NativeCantonCode);
      var sex = null;
      var birth = null;
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = 0;
      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }
      if (sex % 2 == 0) {
        sex = 2;
        this.datafrom.Sex = sex + "";
        this.age = age;
        this.datafrom.Birthday = birth;
        this.currentDate = new Date(this.datafrom.Birthday);
        console.log(this.datafrom.Birthday);
      } else {
        sex = 1;
        // this.hzlist.Sex = sex;
        this.datafrom.Sex = sex + "";
        this.age = age;
        this.datafrom.Birthday = birth;
        this.currentDate = new Date(this.datafrom.Birthday);
        console.log(this.datafrom.Birthday);
      }
    },
    // 社区选择器
    onidListOrg(val) {
      this.datafrom.OrgCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId(val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow(val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onyimiao(val) {
      this.datafrom.yimiao = val.id;
      this.datafrom.yimiaoName = val.Name;
      this.yimiao = false;
    },
    // 结束日期
    ondatafromEndDate(val) {
      this.datafrom.EndDate = this.dateformat(val);
      this.datafromEndDate = false;
    },
    // 开始日期
    datafromStartDateClick(val) {
      this.datafrom.StartDate = this.dateformat(val);
      this.datafromStartDate = false;
    },
    // 社区
    onocode(val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>
